
	import { Component, Vue, Prop } from "vue-property-decorator";
	import { Form, FormItem, Radio, RadioGroup } from "view-design";

	@Component({
		components: {
			Form,
			FormItem,
			Radio,
			RadioGroup
		}
	})
		export default class Evaluate extends Vue {
            @Prop() readonly paramId:any;
            attitude:0 | 1 = 1;
            quality:0 | 1 = 1;
            timeliness:0 | 1 = 1;

            getInfo (oid:string):any {
                return {
                    opt: "dealevent",
                    // eid: this.$route.params.id,
                    eid: this.paramId,
                    oid,
                    attitude: this.attitude,
                    quality: this.quality,
                    speed: this.timeliness
                };
            }
	}
